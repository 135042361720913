import {
  banksPermissions,
  updateProfilePermission,
  usersPermissions,
} from "./RequiredPermissions/general";
import useCheckPermissions from "./useCheckPermissions";

export default function useGeneralPermissions() {
  const canUpdateProfile = useCheckPermissions(updateProfilePermission);
  const canDisplayAllUsers = useCheckPermissions(usersPermissions.displayAllUsers);
  const canCreateUser = useCheckPermissions(usersPermissions.create);
  const canUpdateUser = useCheckPermissions(usersPermissions.update);
  const canDisplayUser = useCheckPermissions(usersPermissions.display);
  const canViewBanks = useCheckPermissions(banksPermissions.viewBanksList);
  const canCreateBank = useCheckPermissions(banksPermissions.createBank);
  const canUpdateBank = useCheckPermissions(banksPermissions.updateBank);
  const canDeleteBank = useCheckPermissions(banksPermissions.deleteBank);

  return {
    canCreateBank,
    canUpdateBank,
    canDeleteBank,
    canUpdateProfile,
    canDisplayAllUsers,
    canDisplayUser,
    canCreateUser,
    canUpdateUser,
    canViewBanks,
  };
}
