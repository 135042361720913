import { ErrorBoundary } from "@newrai/ant-design";
import { ConfigProvider } from "antd";
import en_GB from "antd/lib/locale/en_GB";
import fr_FR from "antd/lib/locale/fr_FR";
import i18n from "config/i18n/i18n";
import { routers } from "config/routes";
import dayjs from "dayjs";
import dayjsBusinessDays from "dayjs-business-days2";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import Cookies from "js-cookie";
import { useRef } from "react";
import { I18nextProvider } from "react-i18next";
import "react-phone-input-2/lib/style.css";
import { RouterProvider } from "react-router-dom";
import "./resources/styles/styles.less";

const CONFIG_TRANSLATIONS = {
  fr: fr_FR,
  en: en_GB,
};

dayjs.extend(dayjsBusinessDays);
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);

export default function App() {
  const cookie = useRef();

  const checkCookie = () => {
    if (!cookie.current) {
      cookie.current = Cookies.get("access_token");
    } else if (cookie.current !== Cookies.get("access_token")) {
      window.location.reload();
    }
  };

  window.setInterval(checkCookie, 1000);

  return (
    <I18nextProvider i18n={i18n}>
      <ConfigProvider locale={CONFIG_TRANSLATIONS[i18n.language]}>
        <ErrorBoundary>
          <RouterProvider
            router={routers}
            future={{
              v7_startTransition: true,
            }}
          />
        </ErrorBoundary>
      </ConfigProvider>
    </I18nextProvider>
  );
}
