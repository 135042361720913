import { Draft, produce } from "immer";
import pipe from "ramda/es/pipe";
import create, { State, StateCreator } from "zustand";
import { devtools } from "zustand/middleware";

import dayjs from "dayjs";
import * as utcPlugin from "dayjs/plugin/utc";

dayjs.extend(utcPlugin.default);

const immer =
  <T extends State>(
    config: StateCreator<T, (fn: (draft: Draft<T>) => void) => void>,
  ): StateCreator<T> =>
  (set, get, api): any =>
    config(fn => set(produce<T>(fn)), get, api);

let createStore = pipe(immer, create);

if (process.env.NODE_ENV === "development") {
  createStore = pipe(immer, devtools, create);
}

const initialState = {
  userData: undefined,
  companySelected: undefined,
  accountingFirmSelected: undefined,
  isCompanySelectOpen: false,
  companyInfo: undefined,
  companyData: undefined,
  companySoftware: undefined,
  createUpdateUserLoading: undefined,
  dataChanged: false,
  saveButtonWasClicked: false,
  externalServiceData: undefined,
  currentCycle: undefined,
};

const setStateHelper = (argName, argValue, set, get) => {
  set(state => ({
    ...state,
    [argName]: typeof argValue === "function" ? argValue(get()[argName]) : argValue,
  }));
};

const useStore = createStore((set, get) => ({
  ...initialState,
  resetStore: () => {
    set(() => ({
      ...initialState,
    }));
  },
  setUserData: userData => {
    setStateHelper("userData", userData, set, get);
  },
  setCompanySoftware: companySoftware => {
    setStateHelper("companySoftware", companySoftware, set, get);
  },
  setCurrentCycle: currentCycle => {
    setStateHelper("currentCycle", currentCycle, set, get);
  },
  setCompanySelected: companySelected => {
    setStateHelper("companySelected", companySelected, set, get);
  },
  setAccountingFirmSelected: accountingFirmSelected => {
    setStateHelper("accountingFirmSelected", accountingFirmSelected, set, get);
  },
  setCompanyInfo: companyInfo => {
    setStateHelper("companyInfo", companyInfo, set, get);
  },
  setIsCompanySelectOpen: isCompanySelectOpen => {
    setStateHelper("isCompanySelectOpen", isCompanySelectOpen, set, get);
  },
  setCreateUpdateUserLoading: createUpdateUserLoading => {
    setStateHelper("createUpdateUserLoading", createUpdateUserLoading, set, get);
  },
  setDataChanged: dataChanged => {
    setStateHelper("dataChanged", dataChanged, set, get);
  },
  setExternalServiceData: externalServiceData => {
    setStateHelper("externalServiceData", externalServiceData, set, get);
  },
  setSaveButtonWasClicked: saveButtonWasClicked => {
    setStateHelper("saveButtonWasClicked", saveButtonWasClicked, set, get);
  },
}));

export default useStore;
