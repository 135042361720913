export const DATE_FORMAT = "DD/MM/YYYY";
export const dateFormatStandard = "YYYY/MM/DD";
export const dateFormatAPI = "YYYY-MM-DD";
export const dateListFormat = ["DD/MM/YYYY", "DD-MM-YYYY", "DDMMYYYY"];
export const URL_DOMAINS_XL_LOGO = [
  "dashboard.asexpertise.lecabinetcomptable.com",
  "dashboard.asexpertise.lecabinetcomptable.fr",
];

export const CI_COMMIT_SHORT_SHA = process.env.REACT_APP_CI_COMMIT_SHORT_SHA;
const currentDomain = window.location.hostname;
const domainArray = currentDomain.split(".").slice(1).join(".");

const neoauth =
  process.env.REACT_APP_ENV === "local"
    ? process.env.REACT_APP_API_URL
    : `https://login.${domainArray}/`;
const appUrl =
  process.env.REACT_APP_ENV === "local"
    ? process.env.REACT_APP_URL
    : `${window.location.protocol}//${window.location.hostname}/`;

const neoactivite =
  process.env.REACT_APP_ENV === "local"
    ? process.env.REACT_APP_NEOACTIVITE
    : `https://neoactivite.${domainArray}/`;

const neoscanExpert =
  process.env.REACT_APP_ENV === "local"
    ? process.env.REACT_APP_NEOSCANEXPERT
    : `https://neoscan-expert.${domainArray}/`;

const neoSsi =
  process.env.REACT_APP_ENV === "local"
    ? process.env.REACT_APP_NEOSSI
    : `https://neossi.${domainArray}/`;

const API = {
  graphqlUrlNeoAuth: process.env.REACT_APP_API_NEOAUTH,
  graphqlUrlNeoSSI: process.env.REACT_APP_API_NEOSSI,
  graphqlUrlMTL: process.env.REACT_APP_API_MTL,
  graphqlUrlNeoCaisse: process.env.REACT_APP_API_NEOCAISSE,
  graphqlUrlCentralAPI: process.env.REACT_APP_API_CENTRALAPI,
  graphqlUrlExternalservices: process.env.REACT_APP_API_EXTERNAL_SERVICES,
  graphqlUrlNeoBookEntries: process.env.REACT_APP_API_NEOBOOKENTRIES,
  graphqlUrlNeoDocs: process.env.REACT_APP_API_NEODOCS,
  graphqlUrlCRM: process.env.REACT_APP_API_CRM,
  graphqlUrlNeoFeed: process.env.REACT_APP_API_NEOFEED,
  graphqlUrlNeoPaie: process.env.REACT_APP_API_NEOPAIE,
  authorizeUrl: `${neoauth}o/authorize/`,
  revokeToken: `${neoauth}o/revoke_token/`,
  clientId: process.env.REACT_APP_API_CLIENT_ID,
  redirectUri: appUrl,
  page404: `${appUrl}404`,
  page500: `${appUrl}500`,
  page401: `${appUrl}application/dashboard`,
  pageError: `${appUrl}error`,
  centralAPIUrl:
    process.env.REACT_APP_API_CENTRALAPI && process.env.REACT_APP_API_CENTRALAPI.slice(0, -8),
  neoactivite: process.env.REACT_APP_API_NEOACTIVITE,
  neoscanExpert: process.env.REACT_APP_NEOSCANEXPERT,
  neoactiviteWS: process.env.REACT_APP_API_NEOACTIVITE_WS,
  fullLoginUrl: "",
  appUrl: `${appUrl}`,
};

API.fullLoginUrl = `${API.authorizeUrl}?response_type=token&client_id=${
  API.clientId
}&scope=read&redirect_uri=${API.redirectUri}${window.location.pathname.substring(
  1,
)}${encodeURIComponent(window.location.search)}`;

export default API;

export const APP_URL = {
  neoActiviteUrl: `${neoactivite}`,
  neoScanExpertUrl: `${neoscanExpert}`,
  neoauthUrl: `${neoauth}`,
  neoSsiUrl: `${neoSsi}`,
};

export const DATE_DISPLAY_FORMAT = "DD/MM/YYYY";
export const HOUR_FORMAT_FOR_API = "HH:mm:ss";
export const NO_API_SOFTWARES = ["FEC", "QUADRAOD", "MYUNISOFT", "CEGID"];
